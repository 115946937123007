import { Box, Grid, IconButton, Stack, SvgIcon, Tooltip, Typography } from '@mui/material';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import 'react-chat-widget/lib/styles.css';

import { RenderRotateProps, rotatePlugin } from '@react-pdf-viewer/rotate';
import { RenderPrintProps, printPlugin } from '@react-pdf-viewer/print';
import { searchPlugin } from '@react-pdf-viewer/search';

import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { QUERY_GET_MY_RECORDS_FILE_BY_ID } from 'graphql/queries/records';
import { Attachments, CommentsPanel, PdfViewer, PdfViewerRef, PropertiesPanel, ShareDialog } from 'ui-component/RecordView';

import { useDispatch } from 'react-redux';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { openSnackbar } from 'store/slices/snackbar';
import {
    IFormatDataValues,
    IGetRecordFileById,
    IMutationSaveAnnotation,
    IUpdateRecordHeader,
    SaveAnnotationVariables
} from 'ui-component/RecordView/types';
import { IGetRecordFields, IGetRecordHeaders, IRecordHeaders, RecordHeadersVariables } from 'ui-component/records/types';
import { MUTATION_UPDATE_RECORD_HEADER } from 'graphql/mutations/bills';
import { QUERY_GET_RECORD_FIELDS, QUERY_GET_RECORD_HEADERS } from 'graphql/queries/bills';
import { UploadPdfBanner } from 'views/CreateRecord/components';
import { useDropzone } from 'react-dropzone';
import { MUTATION_SAVE_ANNOTATION, MUTATION_UPLOAD_FILE } from 'graphql/mutations/records';
import { downloadPdf } from 'ui-component/RecordView/utils';
import { IGetRecordTypes, IUploadPdf, UploadPdfVariables } from 'views/CreateRecord/types';
import { QUERY_GET_RECORD_TYPES } from 'graphql/queries/recordTypes';
import { updateSectionName } from 'store/slices/section';
import SearchSidebar from './components/SearchSidebar';
import { openDrawer } from 'store/slices/menu';
import { SearchOff, Search as SearchIcon, Rotate90DegreesCcw, Download, Print as PrintIcon } from '@mui/icons-material';
import { ReactComponent as SaveNotes } from 'assets/images/icons/save-notes.svg';
import { RotateDirection } from '@react-pdf-viewer/core';
import { FlexibleArea, MainArea } from './components';
import { QUERY_FIND_ALL_LINE_ITEMS_HEADERS } from 'graphql/queries/lineItems';
import { IFindAllLineItemHeaders } from 'ui-component/records/LineItems/types';
import useMyRole from 'hooks/useMyRole';
import { getAttachmentFields, getAttachmentFieldIds } from 'ui-component/RecordView/Attachments/utils';
import { LineItemsRecordView } from 'ui-component/records/LineItems';
import { DocumentViewerProvider } from './context/DocumentViewerProvider';
import { useSelector } from 'store';
import useIsAllowedByRecordType from 'hooks/useIsAllowedByRecordType';
import { AdditionalInfoOptionsEnum, IHighLightPart, LayoutPositionEnum } from './types';
import { getDocumentFileUrl, getLayoutPositionHeight } from './utils';
import { PropertiesPanelRef } from '../PropertiesPanel/v3';

const selectedAdditionalInfoValidOptions = ['ATTACHMENTS', 'COMMENTS', 'LINE_ITEMS'];

export const RECORD_VIEW_COMPONENTS = ['ATTACHMENTS', 'COMMENTS', 'LINE_ITEMS', 'LOG_REPORT', 'ENTITIES', 'BUTTONS'] as const;

export type RecordViewCoreProps = {
    hideSidebar?: boolean;
    editMode?: boolean;
    modalMode?: boolean;
    onChangeDisabledSubmit: (disabled: boolean) => void;
    components?: Partial<(typeof RECORD_VIEW_COMPONENTS)[number]>[];
};

export type RecordViewCoreRef = {
    handleSubmit: () => Promise<boolean>;
};

export const RecordViewCore = forwardRef(
    ({ editMode, hideSidebar, modalMode, onChangeDisabledSubmit, components = [...RECORD_VIEW_COMPONENTS] }: RecordViewCoreProps, ref) => {
        const propertiesPanelRef = useRef<PropertiesPanelRef | null>(null);
        const { search } = useLocation();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const { drawerOpen } = useSelector((store) => store.menu);

        const pdfViewerRef = useRef<PdfViewerRef>(null);

        const queryParams = useMemo(() => new URLSearchParams(search), [search]);
        const roleId = useMyRole();
        const recordId = queryParams.get('recordId') || '';
        const recordTypeId = queryParams.get('recordType') || '';

        const [selectedRecord, setSelectedRecord] = useState<IRecordHeaders | null>(null);
        const [uploadedFile, setFile] = useState<File | null>(null);
        const [isEditMode, setIsEditMode] = useState(editMode || false);
        const [fileAdded, setFileAdded] = useState(false);
        const [layoutPosition, setLayoutPosition] = useState<LayoutPositionEnum>('ONLY_RECORD');
        const [isOpenShare, setIsOpenShare] = useState(false);
        const [showSearchSidebar, setShowSearchSidebar] = useState(false);
        const [selectedAdditionalInfoOption, setSelectedAdditionalInfoOption] = useState<AdditionalInfoOptionsEnum | ''>('');

        const [showLineItemsPanel, setShowLogLineItemsPanel] = useState(false);
        const [showLogCommentsPanel, setShowLogCommentsPanel] = useState(false);

        // Data queries
        const { data: recordTypeData } = useQuery<IGetRecordTypes>(QUERY_GET_RECORD_TYPES);
        const { data: lineItemsHeaderData } = useQuery<IFindAllLineItemHeaders>(QUERY_FIND_ALL_LINE_ITEMS_HEADERS);
        const [getRecordFile, { loading: docLoading, data: docData }] = useLazyQuery<IGetRecordFileById>(QUERY_GET_MY_RECORDS_FILE_BY_ID, {
            fetchPolicy: 'no-cache'
        });
        const [getRecordData, { loading: loadingRecordData }] = useLazyQuery<IGetRecordHeaders, RecordHeadersVariables>(
            QUERY_GET_RECORD_HEADERS,
            {
                fetchPolicy: 'cache-first'
            }
        );
        const [getHeaderData, { data: headerData, loading: loadingHeaderData }] = useLazyQuery<IGetRecordFields>(QUERY_GET_RECORD_FIELDS, {
            variables: { recordType: Number(recordTypeId) },
            fetchPolicy: 'no-cache'
        });

        const { isAllowed: isAllowedToAccessLineItems } = useIsAllowedByRecordType('line item access', Number(recordTypeId));

        const lineItemHeaders = useMemo(
            () =>
                lineItemsHeaderData?.findAllLineItemsByType.find((data) => {
                    const recordTypesArr = data.recordType.map((el) => Number(el.id));
                    return recordTypesArr.includes(Number(recordTypeId));
                }) || null,
            [lineItemsHeaderData, recordTypeId]
        );

        const [uploadFile] = useMutation<IUploadPdf, UploadPdfVariables>(MUTATION_UPLOAD_FILE);
        const [updatePanel, { loading: loadingUpdateRecord }] = useMutation<IUpdateRecordHeader>(MUTATION_UPDATE_RECORD_HEADER, {
            onCompleted(data) {
                setSelectedRecord(data.updateRecordHeader);
            }
        });
        const [saveAnnotations] = useMutation<IMutationSaveAnnotation, SaveAnnotationVariables>(MUTATION_SAVE_ANNOTATION);

        const headersDataFields = useMemo(() => headerData?.getSaasRecordFieldsByRecordTypes, [headerData]);
        const fileId = selectedRecord?.recordFile?.id || '';
        const attachmentFieldIds = getAttachmentFieldIds(headersDataFields);
        const attachmentsData = getAttachmentFields(attachmentFieldIds, selectedRecord?.additionalFields);

        const recordFileUrl = useMemo(() => {
            if (docLoading) return null;
            if (docData?.getMyRecordFileById) return getDocumentFileUrl(docData.getMyRecordFileById);
            if (uploadedFile) return URL.createObjectURL(uploadedFile);

            return null;
        }, [docData?.getMyRecordFileById, docLoading, uploadedFile]);

        const initialHighlightParts = useMemo(() => {
            if (docLoading) return undefined;

            if (!docData?.getMyRecordFileById?.annotation) return [];

            const parsedData = JSON.parse(docData.getMyRecordFileById.annotation) as IHighLightPart[];

            return !Array.isArray(parsedData) ? [] : parsedData;
        }, [docData?.getMyRecordFileById.annotation, docLoading]);

        const rotatePluginInstance = rotatePlugin();
        const { Rotate } = rotatePluginInstance;

        const printPluginInstance = printPlugin();
        const { Print } = printPluginInstance;

        // Search flow
        const searchPluginInstance = searchPlugin();

        useEffect(() => {
            if (showSearchSidebar) {
                dispatch(openDrawer(false));
            }
        }, [showSearchSidebar, dispatch]);

        useEffect(() => {
            dispatch(openDrawer(false));
        }, [dispatch]);

        const handleSubmit = async (_recordType: number, data: IFormatDataValues, showAlerts: boolean = true) => {
            const { baseData, additionalFields } = data;
            let newFileId;
            const formattedData = {
                ...baseData,
                id: Number(recordId),
                additionalFields
            };

            try {
                if (uploadedFile) {
                    const res = await uploadFile({
                        variables: {
                            file: uploadedFile
                        }
                    });
                    newFileId = res.data?.uploadRecordFile.id || null;
                }

                await updatePanel({
                    variables: {
                        data: {
                            ...formattedData,
                            recordFileId: Number(newFileId) || undefined
                        }
                    }
                });
                if (showAlerts)
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: `Update successful`,
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: true
                        })
                    );
                return true;
            } catch (err: any) {
                console.log(err);
                if (showAlerts)
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: `Save changes failed: ${err.message || 'Internal server error.'}`,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                return false;
            }
        };

        const handleDownload = () => {
            if (recordFileUrl) downloadPdf(recordFileUrl, docData?.getMyRecordFileById?.originalFileName);
        };

        const handleSaveAnnotations = async () => {
            try {
                const newParts = pdfViewerRef.current?.getParts();
                const annotationsString = JSON.stringify(newParts);

                await saveAnnotations({
                    variables: {
                        data: {
                            id: Number(fileId),
                            annotation: annotationsString
                        }
                    }
                });

                setShowSearchSidebar(false);
                handleClearHighlights();
                dispatch(
                    openSnackbar({
                        open: true,
                        message: `Highlight saved successful`,
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
            } catch (err: any) {
                console.log(err);

                dispatch(
                    openSnackbar({
                        open: true,
                        message: `Save highlight failed: ${err.message || 'Internal server error.'}`,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            }
        };

        const { getRootProps, getInputProps, open } = useDropzone({
            maxFiles: 1,
            multiple: false,
            accept: {
                'image/*': [],
                'application/pdf': []
            },
            onDrop: (acceptedFiles) => {
                setFile(acceptedFiles[0]);
                setFileAdded(true);
            }
        });

        const handleClearHighlights = useCallback(() => {
            if (searchPluginInstance) {
                const { clearHighlights } = searchPluginInstance;
                clearHighlights();
            }
        }, [searchPluginInstance]);

        useEffect(() => {
            (async () => {
                try {
                    const { data } = await getRecordData({
                        variables: {
                            data: { id: Number(recordId), recordType: Number(recordTypeId) },
                            pagination: { offset: 0, limit: 1 }
                        }
                    });

                    if (!data?.findRecordHeader.records[0]) return;
                    const recordData = data.findRecordHeader.records[0];

                    setSelectedRecord(recordData);

                    if (recordData.recordFile?.id)
                        getRecordFile({
                            variables: {
                                recordFileId: Number(recordData.recordFile.id)
                            }
                        });
                } catch (err) {
                    navigate('/');
                }
            })();
        }, [getRecordData, getRecordFile, navigate, recordId, recordTypeId]);

        useEffect(() => {
            dispatch(
                updateSectionName({
                    sectionName: recordTypeData?.getRecordTypes.find((type) => type.id === String(recordTypeId))?.name || 'Document Viewer'
                })
            );
        }, [dispatch, recordTypeData, recordTypeId]);

        useEffect(() => {
            if (recordTypeId) getHeaderData();
        }, [getHeaderData, recordTypeId]);

        useEffect(() => {
            if (editMode) setIsEditMode(true);
        }, [editMode]);

        useImperativeHandle(ref, () => ({
            handleSubmit: () => propertiesPanelRef.current?.handleSubmit()
        }));

        if ((!Number(recordId) || !Number(recordTypeId)) && !modalMode) return <Navigate to="../" />;

        return (
            <DocumentViewerProvider>
                <Box height={modalMode ? '100%' : 'calc(100vh - 88px)'} position="relative">
                    <Grid container sx={{ height: '100%', '& > div': { maxHeight: '100%' } }}>
                        {showSearchSidebar && (
                            <Grid item xs={2} sx={{ bgcolor: 'white', padding: 0 }}>
                                <SearchSidebar
                                    searchPluginInstance={searchPluginInstance}
                                    setShowSearchSidebard={setShowSearchSidebar}
                                    handleClearHighlights={handleClearHighlights}
                                />
                            </Grid>
                        )}
                        <MainArea showSearchSidebard={showSearchSidebar} drawerOpen={drawerOpen}>
                            <Grid
                                item
                                xs={12}
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    height: getLayoutPositionHeight('RECORD', layoutPosition),
                                    overflowY: 'hidden',
                                    overflowX: 'hidden',
                                    display: `${!docLoading && recordFileUrl ? 'block' : 'flex'}`,
                                    position: 'relative'
                                }}
                            >
                                {!!recordFileUrl && !!fileId && (
                                    <Box
                                        sx={(theme) => ({
                                            position: 'absolute',
                                            top: '0.5rem',
                                            right: '1.7rem',
                                            background: 'transparent',
                                            zIndex: '999',
                                            height: '100%',
                                            width: '40px'
                                        })}
                                    >
                                        <Stack spacing={2}>
                                            <Tooltip title={!showSearchSidebar ? 'Search' : 'Close Search Bar'}>
                                                <IconButton
                                                    sx={(theme) => ({
                                                        color: theme.palette.primary[400],
                                                        backgroundColor: '#FFF',
                                                        fontWeight: 600,
                                                        '&:hover': { color: theme.palette.primary[400], backgroundColor: '#FFF' }
                                                    })}
                                                    onClick={() => {
                                                        if (showSearchSidebar) {
                                                            handleClearHighlights();
                                                        }
                                                        setShowSearchSidebar?.(!showSearchSidebar);
                                                    }}
                                                >
                                                    {!showSearchSidebar ? <SearchIcon /> : <SearchOff />}
                                                </IconButton>
                                            </Tooltip>
                                            <Rotate direction={RotateDirection.Backward}>
                                                {(props: RenderRotateProps) => (
                                                    <Tooltip title="Rotate">
                                                        <IconButton
                                                            sx={(theme) => ({
                                                                color: theme.palette.primary[400],
                                                                backgroundColor: '#FFF',
                                                                fontWeight: 600,
                                                                '&:hover': { color: theme.palette.primary[400], backgroundColor: '#FFF' }
                                                            })}
                                                            onClick={props.onClick}
                                                            color="secondary"
                                                        >
                                                            <Rotate90DegreesCcw />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Rotate>
                                            <Tooltip title="Save Annotations">
                                                <IconButton
                                                    sx={(theme) => ({
                                                        color: theme.palette.primary[400],
                                                        backgroundColor: '#FFF',
                                                        fontWeight: 600,
                                                        '&:hover': { color: theme.palette.primary[400], backgroundColor: '#FFF' }
                                                    })}
                                                    onClick={handleSaveAnnotations}
                                                >
                                                    <SvgIcon component={SaveNotes} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Download">
                                                <IconButton
                                                    sx={(theme) => ({
                                                        color: theme.palette.primary[400],
                                                        backgroundColor: '#FFF',
                                                        fontWeight: 600,
                                                        '&:hover': { color: theme.palette.primary[400], backgroundColor: '#FFF' }
                                                    })}
                                                    onClick={handleDownload}
                                                >
                                                    <Download />
                                                </IconButton>
                                            </Tooltip>
                                            <Print>
                                                {(props: RenderPrintProps) => (
                                                    <Tooltip title="Print">
                                                        <IconButton
                                                            sx={(theme) => ({
                                                                color: theme.palette.primary[400],
                                                                backgroundColor: '#FFF',
                                                                fontWeight: 600,
                                                                '&:hover': { color: theme.palette.primary[400], backgroundColor: '#FFF' }
                                                            })}
                                                            onClick={props.onClick}
                                                            color="secondary"
                                                        >
                                                            <PrintIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Print>
                                        </Stack>
                                    </Box>
                                )}
                                {!loadingUpdateRecord && !loadingRecordData && !fileId && !isEditMode && (
                                    <Typography variant="body2">
                                        There is no document associated with this record. You can add a document by clicking on the edit
                                        icon
                                    </Typography>
                                )}
                                {!fileId && isEditMode && !recordFileUrl && (
                                    <Box sx={{ width: '75%' }}>
                                        <UploadPdfBanner rootProps={getRootProps} />
                                        <input accept=".jpg, .jpeg, .png, .gif, .svg, .pdf" {...getInputProps()} />
                                    </Box>
                                )}
                                {fileId && !docLoading && !recordFileUrl && (
                                    <Typography variant="body2">
                                        There was a problem loading the document associated with this record. Please try again later
                                    </Typography>
                                )}
                                {!docLoading && recordFileUrl && initialHighlightParts && (
                                    <PdfViewer
                                        ref={pdfViewerRef}
                                        initialParts={initialHighlightParts}
                                        url={recordFileUrl}
                                        rotateInstance={rotatePluginInstance}
                                        printInstance={printPluginInstance}
                                        searchInstance={searchPluginInstance}
                                    />
                                )}
                            </Grid>

                            {!!lineItemHeaders && selectedAdditionalInfoOption === 'LINE_ITEMS' && (
                                <FlexibleArea
                                    title="Line Items"
                                    toggleFullHeight={() =>
                                        setLayoutPosition(layoutPosition === 'SPLIT_VIEW' ? 'ONLY_ADDITIONAL_INFO' : 'SPLIT_VIEW')
                                    }
                                    setToggleHistoryPanel={setShowLogLineItemsPanel}
                                    toggleHistoryPanel={showLineItemsPanel}
                                    showHistoryPanel
                                    fullHeight={layoutPosition === 'ONLY_ADDITIONAL_INFO'}
                                    height={getLayoutPositionHeight('ADDITIONAL_INFO', layoutPosition)}
                                    showDivider={layoutPosition === 'SPLIT_VIEW'}
                                    onClose={() => {
                                        setLayoutPosition('ONLY_RECORD');
                                        setSelectedAdditionalInfoOption(
                                            selectedAdditionalInfoValidOptions.includes(selectedAdditionalInfoOption) ? '' : 'LINE_ITEMS'
                                        );
                                    }}
                                    contentSx={{ pt: 1 }}
                                >
                                    <LineItemsRecordView
                                        modalMode={modalMode}
                                        isOpen={(['SPLIT_VIEW', 'ONLY_LINE_ITEMS'] as LayoutPositionEnum[]).includes(layoutPosition)}
                                        recordId={Number(recordId)}
                                        recordTypeId={Number(recordTypeId)}
                                        showCustomNoRowsOverlay={layoutPosition === 'ONLY_ADDITIONAL_INFO'}
                                    />
                                </FlexibleArea>
                            )}

                            {selectedAdditionalInfoOption === 'COMMENTS' && (
                                <FlexibleArea
                                    title="Comments"
                                    toggleFullHeight={() =>
                                        setLayoutPosition(layoutPosition === 'SPLIT_VIEW' ? 'ONLY_ADDITIONAL_INFO' : 'SPLIT_VIEW')
                                    }
                                    setToggleHistoryPanel={setShowLogCommentsPanel}
                                    toggleHistoryPanel={showLogCommentsPanel}
                                    showHistoryPanel
                                    fullHeight={layoutPosition === 'ONLY_ADDITIONAL_INFO'}
                                    height={getLayoutPositionHeight('ADDITIONAL_INFO', layoutPosition)}
                                    showDivider={layoutPosition === 'SPLIT_VIEW'}
                                    onClose={() => {
                                        setLayoutPosition('ONLY_RECORD');
                                        setSelectedAdditionalInfoOption('');
                                    }}
                                    contentSx={{ pt: 1 }}
                                >
                                    <CommentsPanel />
                                </FlexibleArea>
                            )}

                            {selectedAdditionalInfoOption === 'ATTACHMENTS' && (
                                <FlexibleArea
                                    title="Attachments"
                                    toggleFullHeight={() =>
                                        setLayoutPosition(layoutPosition === 'SPLIT_VIEW' ? 'ONLY_ADDITIONAL_INFO' : 'SPLIT_VIEW')
                                    }
                                    fullHeight={layoutPosition === 'ONLY_ADDITIONAL_INFO'}
                                    height={getLayoutPositionHeight('ADDITIONAL_INFO', layoutPosition)}
                                    showDivider={layoutPosition === 'SPLIT_VIEW'}
                                    onClose={() => {
                                        setLayoutPosition('ONLY_RECORD');
                                        setSelectedAdditionalInfoOption('');
                                    }}
                                >
                                    <Attachments
                                        fullHeight={layoutPosition === 'ONLY_ADDITIONAL_INFO'}
                                        attachmentFieldIds={attachmentFieldIds}
                                        attachmentsData={attachmentsData}
                                        onUpdateAttachment={(data) => handleSubmit(Number(recordTypeId), data, false)}
                                        recordTypeId={recordTypeId}
                                        roleId={roleId}
                                    />
                                </FlexibleArea>
                            )}
                        </MainArea>

                        <Grid item xs={drawerOpen ? 3.4 : 3} sx={{ bgcolor: 'white', padding: 0 }}>
                            <PropertiesPanel
                                ref={propertiesPanelRef}
                                loading={loadingRecordData || loadingHeaderData || loadingUpdateRecord}
                                recordData={selectedRecord}
                                recordType={recordTypeId}
                                hasFile={!!fileId || !!uploadedFile}
                                onSubmit={handleSubmit}
                                isEditMode={setIsEditMode}
                                initialEditMode={isEditMode}
                                fileAdded={isEditMode && fileAdded}
                                onUploadFile={open}
                                showLogCommentsPanel={showLogCommentsPanel}
                                setShowLogCommentsPanel={setShowLogCommentsPanel}
                                showLineItemsPanel={showLineItemsPanel}
                                setShowLogLineItemsPanel={setShowLogLineItemsPanel}
                                onToggleLineItems={
                                    lineItemHeaders && isAllowedToAccessLineItems
                                        ? () => {
                                              setLayoutPosition(
                                                  layoutPosition === 'SPLIT_VIEW' && selectedAdditionalInfoOption === 'LINE_ITEMS'
                                                      ? 'ONLY_RECORD'
                                                      : 'SPLIT_VIEW'
                                              );
                                              setSelectedAdditionalInfoOption('LINE_ITEMS');
                                          }
                                        : undefined
                                }
                                onToggleComments={() => {
                                    setLayoutPosition(
                                        layoutPosition === 'SPLIT_VIEW' && selectedAdditionalInfoOption === 'COMMENTS'
                                            ? 'ONLY_RECORD'
                                            : 'SPLIT_VIEW'
                                    );
                                    setSelectedAdditionalInfoOption('COMMENTS');
                                }}
                                onToggleAttachments={() => {
                                    setLayoutPosition(
                                        layoutPosition === 'SPLIT_VIEW' && selectedAdditionalInfoOption === 'ATTACHMENTS'
                                            ? 'ONLY_RECORD'
                                            : 'SPLIT_VIEW'
                                    );
                                    setSelectedAdditionalInfoOption('ATTACHMENTS');
                                }}
                                recordId={Number(recordId)}
                                hideSidebar={hideSidebar}
                                modalMode={modalMode}
                                onChangeDisabledSubmit={onChangeDisabledSubmit}
                                components={components}
                            />
                        </Grid>
                    </Grid>
                    <ShareDialog open={isOpenShare} onClose={() => setIsOpenShare(false)} />
                </Box>
            </DocumentViewerProvider>
        );
    }
);
