import { Box, CircularProgress, Fab, Grid, Tooltip } from '@mui/material';
import { Close, Edit, Save } from '@mui/icons-material';
import { PanelTitle } from '../utils';

export type FooterProps = {
    panelState: PanelTitle;
    onEdit?: () => void;
    onSave?: () => Promise<void>;
    onCancel?: () => void;
    isCreate?: boolean;
    disabledButtons?: boolean;
    hideSaveAnOrder?: boolean;
    disabledByRequired?: boolean;
    isRecordView?: boolean;
};

export const FooterOptions = ({
    panelState,
    onEdit,
    onSave,
    onCancel,
    isCreate,
    disabledButtons,
    hideSaveAnOrder = true,
    disabledByRequired = false,
    isRecordView = true
}: FooterProps) => (
    <Grid container justifyContent="flex-end" sx={hideSaveAnOrder ? { borderTop: 'solid 1px #D9D9D9', py: '12px' } : {}}>
        {panelState === PanelTitle.View && hideSaveAnOrder && onEdit && isRecordView && (
            <Tooltip title="Edit">
                <Fab color="secondary" onClick={onEdit} sx={{ width: 40, height: 40 }} disabled={disabledButtons}>
                    <Edit />
                </Fab>
            </Tooltip>
        )}

        {panelState === PanelTitle.Edit && isRecordView && (
            <Box gap={1} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                {!isCreate && (
                    <Tooltip title="Discard Changes">
                        <Fab onClick={onCancel} sx={{ width: 40, height: 40, boxShadow: 'none', '& svg': { color: '#858585' } }}>
                            <Close />
                        </Fab>
                    </Tooltip>
                )}
                <Tooltip title="Save Changes">
                    <Fab color="secondary" onClick={onSave} sx={{ width: 40, height: 40, boxShadow: 'none' }} disabled={disabledButtons}>
                        {disabledButtons ? <CircularProgress size={20} color="inherit" /> : <Save />}
                    </Fab>
                </Tooltip>
            </Box>
        )}

        {panelState === PanelTitle.Edit && !isRecordView && (
            <Tooltip title="Save">
                <Fab color="secondary" onClick={onSave} sx={{ width: 40, height: 40 }} disabled={disabledButtons || disabledByRequired}>
                    <Save />
                </Fab>
            </Tooltip>
        )}
    </Grid>
);

export default FooterOptions;
