/* eslint-disable import/prefer-default-export */
import { Button, Chip, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import pdfIcon from 'assets/images/icons/pdf.png';
import { valueGetterList } from './valueGetterList';
import { IObjectValues, IRecordField, IRecordHeaders } from '../types';
import { DropdownRender, CheckboxGridRender, MultiselectGridRender } from '../renderCellComponents';
import { ListValue } from 'views/backoffice/CustomLists/types';
import { Add } from '@mui/icons-material';
import dayjs from 'dayjs';

export const renderCellList: Record<string, any> = {
    enabled: ({ value }: GridRenderCellParams<IRecordHeaders>) =>
        value ? (
            <Chip
                label="Active"
                size="small"
                sx={(theme) => ({
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                    color: theme.palette.success.dark
                })}
            />
        ) : (
            <Chip
                label="Deleted"
                size="small"
                sx={(theme) => ({
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                    color: theme.palette.orange.dark
                })}
            />
        ),
    dropdowns:
        (
            listValues: ListValue[],
            isListObject: boolean,
            handleClick: (
                field: string,
                id: number,
                objectValuesDefinition: IObjectValues[],
                isEditable: boolean,
                objectDefinitionId: string,
                isListObject?: boolean
            ) => void,
            isObjectEditable: boolean,
            listId?: number,
            objectField?: string,
            objectDefinitionId?: number | string
        ) =>
        (props: GridRenderCellParams<IRecordHeaders>) =>
            (
                <DropdownRender
                    listValues={listValues}
                    value={props.value}
                    listId={listId}
                    isListObject={isListObject}
                    handleClickObject={handleClick}
                    isObjectEditable={isObjectEditable}
                    objectField={objectField ?? ''}
                    rowId={props.id}
                    objectDefinitionId={objectDefinitionId}
                />
            ),
    multiselect:
        (listValues: ListValue[], listId?: number) =>
        ({ value }: GridRenderCellParams) =>
            <MultiselectGridRender listValues={listValues} value={value} listId={listId} />,
    checkboxes: ({ value }: GridRenderCellParams<IRecordHeaders>) => <CheckboxGridRender value={value} />,
    url: ({ value }: GridRenderCellParams<IRecordHeaders>) =>
        value ? (
            <a href={value.includes('http') || value.includes('https') ? value : `https://${value}`} target="_blank" rel="noreferrer">
                {value}
            </a>
        ) : (
            ''
        ),
    date: (params: GridRenderCellParams<IRecordHeaders>) => {
        if (params.value) {
            return <>{dayjs(params.value).format('MM/DD/YYYY')}</>;
        }
        return '';
    },
    object:
        (
            handleClick: (
                field: string,
                id: number,
                objectValuesDefinition: IObjectValues[],
                isEditable: boolean,
                objectDefinitionId: string,
                isListObject?: boolean
            ) => void,
            isEditable: boolean,
            objectDefinitionId: number | string
        ) =>
        (props: GridRenderCellParams<IRecordHeaders>) => {
            const {
                value,
                row: { additionalFields }
            } = props;
            const displayValue: string[] = [];
            const objectValuesDefinition: IObjectValues[] = [];
            const currentField = additionalFields.find((field: any) => field.objectValue?.id === value);
            if (!currentField) {
                return (
                    <Typography
                        className="gridObjectValue"
                        data-field={props.field}
                        data-id={props.id}
                        data-editable={String(isEditable)}
                        data-objdef={JSON.stringify(objectValuesDefinition)}
                        onClick={() => {
                            handleClick(props.field, +props.id, objectValuesDefinition, isEditable, String(objectDefinitionId));
                        }}
                        sx={(theme) => ({
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            color: theme.palette.primary[400],
                            cursor: 'pointer',
                            textDecoration: 'underline'
                        })}
                    >
                        <Add fontSize="small" /> Add Data
                    </Typography>
                );
            }
            if (currentField.objectValue) {
                const {
                    objectValue: { objectValues }
                } = currentField;
                [...objectValues]
                    ?.sort((a, b) => {
                        const orderA = a.objectProperty.order; // ignore upper and lowercase
                        const orderB = b.objectProperty.order; // ignore upper and lowercase
                        if (orderA < orderB) {
                            return -1;
                        }
                        if (orderA > orderB) {
                            return 1;
                        }
                        return 0;
                    })
                    .forEach((val) => {
                        objectValuesDefinition.push(val);
                        if (val.objectProperty.isDisplayable) {
                            let { value: objValue } = val;
                            if (val.objectProperty.dataType === 'date') objValue = dayjs(objValue).format('YYYY-MM-DD');
                            if (val.objectProperty.dataType === 'datetime') objValue = dayjs(objValue).format('YYYY-MM-DD hh:mm a');
                            displayValue.push(objValue);
                        }
                    });
            }
            if (displayValue.length !== 0) {
                return (
                    <Typography
                        className="gridObjectValue"
                        data-field={props.field}
                        data-id={props.id}
                        data-editable={String(isEditable)}
                        data-objdef={JSON.stringify(objectValuesDefinition)}
                        onClick={() => {
                            handleClick(props.field, +props.id, objectValuesDefinition, isEditable, String(objectDefinitionId));
                        }}
                        sx={(theme) => ({ color: theme.palette.secondary.main, cursor: 'pointer', textDecoration: 'underline' })}
                    >
                        {displayValue.join(', ') || 'No Display Value Setted'}
                    </Typography>
                );
            }
            return (
                <Typography
                    className="gridObjectValue"
                    data-field={props.field}
                    data-id={props.id}
                    data-editable={String(isEditable)}
                    data-objdef={JSON.stringify(objectValuesDefinition)}
                    onClick={() => {
                        handleClick(props.field, +props.id, objectValuesDefinition, isEditable, String(objectDefinitionId));
                    }}
                    sx={(theme) => ({
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                        color: theme.palette.primary[400],
                        cursor: 'pointer',
                        textDecoration: 'underline'
                    })}
                >
                    <Add fontSize="small" /> Add Data
                </Typography>
            );
        },
    Attachment:
        (handleClick?: (fileId: string, recordId: string) => void) =>
        ({ row, id }: GridRenderCellParams<IRecordHeaders>) =>
            (
                <Button
                    variant="text"
                    onClick={() => handleClick?.(String(row.recordFile.id || ''), String(id))}
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                >
                    <img src={pdfIcon} alt="pdf-icon" />
                </Button>
            )
};

// TODO: add new arguments at the end of the function
export const getRenderCell = (
    key: string,
    isEditable?: boolean,
    handleClickObject?: (
        field: string,
        id: number,
        objectValuesDefinition: IObjectValues[],
        isEditable: boolean,
        isListObject?: boolean
    ) => void,
    recordField?: IRecordField,
    listValues?: ListValue[]
) => {
    if ((recordField?.dataType === 'dropdown' || recordField?.dataType === 'single-select') && recordField.listType) {
        return renderCellList.dropdowns(
            listValues,
            !!recordField.listType?.objectDefinition,
            handleClickObject,
            false,
            recordField.listType.id,
            recordField.listType?.objectDefinition?.name,
            recordField.listType?.objectDefinition?.id
        );
    }
    if ((recordField?.dataType === 'multiselect' || recordField?.dataType === 'multiselect-checkbox') && recordField.listType)
        return renderCellList.multiselect(listValues, recordField.listType.id);
    if (recordField?.dataType === 'checkbox') return renderCellList.checkboxes;
    if (recordField?.dataType === 'url') return renderCellList.url;
    if (recordField?.dataType === 'object')
        return renderCellList.object(handleClickObject, isEditable, recordField.objectDefinition?.id ?? 0);
    if (recordField?.dataType === 'date') return renderCellList.date;

    if (recordField?.dataType === 'phone') {
        return ({ value }: GridRenderCellParams<IRecordHeaders>) => {
            // eslint-disable-next-line no-useless-escape
            const phoneValue = value ? `+${value.replace(/[\[\]]/g, '')}` : '';
            const phoneWithoutComas = phoneValue.replace(/,/g, ' ');
            return <Typography>{phoneWithoutComas}</Typography>;
        };
    }

    if (valueGetterList[key] || !renderCellList[key]) return undefined;

    return renderCellList[key];
};
