import { IRecordFile, LayoutPositionEnum } from './types';

export const getDocumentFileUrl = (recordFile: IRecordFile) => {
    try {
        const buffer = Buffer.from(recordFile.fileEncoded, 'base64');
        const blob = new Blob([buffer], { type: 'application/pdf' });

        return URL.createObjectURL(blob);
    } catch (err) {
        console.log(err);
        return '';
    }
};

/**
 * Returns the correct height for given module and state
 *
 * @param module {'RECORD' | 'LINE_ITEMS'} module to add style
 * @param currentState {LayoutPositionEnum} current state of layout
 */
export const getLayoutPositionHeight = (module: 'RECORD' | 'ADDITIONAL_INFO', currentState: LayoutPositionEnum) => {
    switch (currentState) {
        case 'ONLY_ADDITIONAL_INFO':
            return module === 'ADDITIONAL_INFO' ? '100%' : 0;
        case 'SPLIT_VIEW':
            return module === 'ADDITIONAL_INFO' ? '50%' : '50%';
        default:
            return module === 'ADDITIONAL_INFO' ? 0 : '100%';
    }
};

/**
 * Extracts the text value from the first block of a JSON string.
 *
 * @param json - A JSON string expected to contain an object with a 'blocks' array.
 * @returns The text value from the first block if available, otherwise an error message.
 */
export const extractTextFromJson = (json: string, valueOnEmpty: string) => {
    if (!json) {
        return valueOnEmpty;
    }

    try {
        // Parse the JSON string
        const parsedData = JSON.parse(json);

        // Ensure parsedData is an object and has blocks array
        if (typeof parsedData !== 'object' || !Array.isArray(parsedData.blocks)) {
            return json || valueOnEmpty;
        }

        // Extract the text value from the first block
        const textValue = parsedData.blocks[0]?.text || json || valueOnEmpty;

        return textValue;
    } catch (error) {
        return json ?? valueOnEmpty;
    }
};
