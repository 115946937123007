import { FormControl, Grid, List, Skeleton, Typography } from '@mui/material';
import { IRecordField, IRecordFieldsList } from 'ui-component/records/types';
import { FieldToView, RenderViewField } from './RenderViewField';
import { useSelector } from 'store';

export interface ViewPanelProps {
    fields: Record<keyof IRecordFieldsList, FieldToView>;
    loading: boolean;
    handleOpenViewObjectPanel: (field: IRecordField, fieldName: string, editable: boolean) => void;
    headerData: IRecordFieldsList | null;
}

const ViewPanel = ({ fields, loading, handleOpenViewObjectPanel, headerData }: ViewPanelProps) => {
    const { drawerOpen } = useSelector((state) => state.menu);

    return loading ? (
        <List sx={{ overflowX: 'hidden', width: '21vw' }}>
            {[1, 2, 3, 4, 5].map((el) => (
                <Grid component="li" key={el} container item xs={12} ml={2} mr={1} sx={{ my: '20px' }}>
                    <Grid item xs={5}>
                        <Skeleton height={20} animation="wave" />
                    </Grid>
                    <Grid item xs={8} mt={1}>
                        <Skeleton height={30} animation="wave" />
                    </Grid>
                </Grid>
            ))}
        </List>
    ) : (
        <Grid container data-testid="details-list">
            <FormControl style={{ width: '98%', paddingLeft: 2 }} size="small">
                {fields.recordNumber && (
                    <Grid item xs={12} style={{ paddingBottom: 0, paddingTop: 10 }}>
                        <Grid container sx={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                            <Grid item xs={drawerOpen ? 12 : 6} paddingLeft="20px">
                                <Typography style={{ fontWeight: 600, fontSize: '13px' }}>Record Number</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{ paddingLeft: 10, padding: 10, fontWeight: 400, fontSize: '13px' }} color="#818181">
                                    {fields.recordNumber.value}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid>
                    {Object.entries(fields)
                        .filter(([key]) => key !== 'recordNumber')
                        .map(([label, data]) => (
                            <RenderViewField
                                field={{ ...data, label }}
                                key={label}
                                headerData={headerData}
                                handleOpenViewObjectPanel={handleOpenViewObjectPanel}
                            />
                        ))}
                </Grid>
            </FormControl>
        </Grid>
    );
};

export default ViewPanel;
