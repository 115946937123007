import { SvgIcon, SvgIconProps } from '@mui/material';

export const EyeClosed = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.35933 3.35456C4.0027 2.53593 3.81988 1.66043 3.81988 0.777588H1.81988C1.81988 1.78254 2.00031 2.778 2.3513 3.72105L0.608868 5.08747C0.174278 5.42828 -0.002887 6.1166 0.213159 6.62487C0.429206 7.13314 0.956651 7.2689 1.39124 6.92809L3.24458 5.47469C3.61061 6.02727 4.04053 6.53964 4.52672 7.00191C4.72712 7.19245 4.93617 7.37361 5.15311 7.54496C5.01692 7.62696 4.90177 7.76 4.82546 7.93952L3.66862 10.6611C3.45258 11.1694 3.62974 11.8577 4.06433 12.1985C4.49892 12.5393 5.02637 12.4036 5.24241 11.8953L6.39925 9.1737C6.49891 8.93925 6.5149 8.6665 6.46056 8.40721C6.79652 8.58993 7.14471 8.75245 7.50315 8.89361C8.3033 9.20874 9.14402 9.41333 10.0001 9.50299V13.001C10.0001 13.5532 10.4478 14.001 11.0001 14.001C11.5523 14.001 12.0001 13.5532 12.0001 13.001V9.50298C12.856 9.4133 13.6967 9.20872 14.4968 8.89361C14.677 8.82266 14.8545 8.74631 15.0293 8.66471C15.0247 8.8412 15.0539 9.0161 15.1209 9.1737L15.9389 11.0982C16.1549 11.6064 16.6824 11.7422 17.117 11.4014C17.5516 11.0606 17.7287 10.3723 17.5127 9.86399L16.6947 7.93952C16.6638 7.86691 16.6266 7.80191 16.5842 7.74481C16.896 7.5163 17.1931 7.26827 17.4732 7.00191C17.9592 6.53983 18.389 6.02769 18.7549 5.47537L20.6087 6.9291C21.0433 7.26991 21.5707 7.13415 21.7868 6.62587C22.0028 6.1176 21.8256 5.42928 21.391 5.08847L19.6484 3.72185C19.9995 2.77856 20.1801 1.78283 20.1801 0.777588H18.1801C18.1801 1.66043 17.9973 2.53593 17.6406 3.35456C17.2839 4.17333 16.7596 4.92066 16.0951 5.55248C15.4305 6.18441 14.6388 6.68819 13.7639 7.03273C12.889 7.3773 11.9497 7.55521 11 7.55521C10.0503 7.55521 9.11093 7.3773 8.23603 7.03273C7.36118 6.68819 6.56945 6.18441 5.90483 5.55248C5.24031 4.92066 4.71603 4.17333 4.35933 3.35456Z"
                fill="#858585"
            />
        </svg>
    </SvgIcon>
);
