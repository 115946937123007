import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { ExpandMore, HighlightOff } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { FormattedMessage } from 'react-intl';
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_FIELD, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { lineItemLogsColumns } from './columns';
import { LineItemLog } from 'ui-component/records/LineItems/types';
import { mapRecordLogToLineItemAuditLogTable } from 'ui-component/records/LineItems/utils';
import { LogReportMode } from 'ui-component/RecordView/PropertiesPanel/utils';

export type FullHistoryDialogProps = {
    open: boolean;
    onClose: () => void;
    lineItemId: number;
    items?: any;
    onFetchMore: () => void;
    total: number;
    logReportMode: string;
    loading: boolean;
};

const useStyles = makeStyles((theme) => ({
    evenRow: {},
    oddRow: {
        backgroundColor: `${theme.palette.primary[200]} !important`
    }
}));

const CustomDetailPanelToggle = (props: { shouldShow: boolean }) => {
    const { shouldShow } = props;
    return (
        <>
            {shouldShow ? (
                <IconButton tabIndex={-1}>
                    <ExpandMore
                        sx={{
                            transition: (theme) =>
                                theme.transitions.create('transform', {
                                    duration: theme.transitions.duration.shortest
                                })
                        }}
                        fontSize="inherit"
                    />
                </IconButton>
            ) : (
                <Box />
            )}
        </>
    );
};

const HistoryPanelContent = ({ row }: { row: GridValidRowModel }) => (
    <>
        {row.objectLogs ? (
            <DataGridPro
                columns={[
                    ...lineItemLogsColumns,
                    {
                        field: 'id',
                        width: 230,
                        renderHeader: () => '',
                        renderCell: () => ''
                    }
                ]}
                autoHeight
                rows={row.objectLogs}
                getRowHeight={() => 'auto'}
                onCellKeyDown={(_params, event) => {
                    event.defaultMuiPrevented = true;
                }}
                sx={(theme) => ({
                    '& *::-webkit-scrollbar-thumb': {
                        borderRadius: '8px',
                        background: '#C0C0C0',
                        width: '4px'
                    },
                    '& *::-webkit-scrollbar': {
                        width: '4px',
                        borderRadius: '8px',
                        height: '8px'
                    },
                    '& .MuiDataGrid-columnHeaders': { display: 'none' },
                    '& .MuiDataGrid-virtualScroller': {
                        marginTop: '0 !important',
                        border: `solid 1px ${theme.palette.secondary.main}`,
                        overflowX: 'hidden'
                    },
                    '& .MuiDataGrid-row': {
                        alignItems: 'center',
                        border: 'solid 1px #dddddd',
                        borderRight: 'none',
                        borderLeft: 'none',
                        py: 1
                    },
                    '&.MuiDataGrid-root .MuiDataGrid-cell': {
                        outlineColor: 'transparent !important',
                        outlineWidth: 0
                    },
                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                        outlineColor: 'transparent !important',
                        outlineWidth: 0
                    }
                })}
                disableRowSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                hideFooter
            />
        ) : (
            <></>
        )}
    </>
);

const LineItemHistoryDialog = ({
    open,
    onClose,
    lineItemId,
    items,
    loading,
    onFetchMore,
    total,
    logReportMode
}: FullHistoryDialogProps) => {
    const rowsLogs: LineItemLog[] = items || [];
    const rowIds = Array.from(new Set(rowsLogs.map((log) => log.row.id)));

    const classes = useStyles();

    const rows = lineItemId
        ? mapRecordLogToLineItemAuditLogTable(lineItemId, rowsLogs)
        : rowIds?.map((id) => mapRecordLogToLineItemAuditLogTable(id, rowsLogs)).reduce((pre, cur) => pre.concat(cur), []) ?? [];

    const apiRef = useGridApiRef();

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xl">
            {open && (
                <>
                    <DialogTitle
                        id="form-dialog-title"
                        sx={{
                            fontSize: '24px',
                            fontWeight: '500',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingBottom: 0
                        }}
                    >
                        <Box>
                            <FormattedMessage id={logReportMode === LogReportMode.LineItems ? 'Full Line Items History' : 'Data Update'} />
                        </Box>
                        <IconButton onClick={onClose}>
                            <HighlightOff sx={(theme) => ({ color: theme.palette.secondary.main })} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{ paddingBottom: 0 }}>
                        <Box sx={{ height: '400px' }}>
                            <DataGridPro
                                apiRef={apiRef}
                                columns={[
                                    {
                                        field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
                                        width: 40,
                                        renderHeader: () => '',
                                        renderCell: (params) => (
                                            <CustomDetailPanelToggle shouldShow={(params?.row?.objectLogs?.length ?? 0) > 0} />
                                        )
                                    },
                                    ...lineItemLogsColumns
                                ]}
                                rows={rows}
                                loading={loading}
                                getRowHeight={() => 'auto'}
                                getRowClassName={(params) => {
                                    if (apiRef.current.state.detailPanel.expandedRowIds.includes(params.id))
                                        return 'row-detail-panel-expanded';
                                    return params.indexRelativeToCurrentPage % 2 !== 0 ? classes.oddRow : classes.evenRow;
                                }}
                                getDetailPanelHeight={() => 'auto'}
                                getDetailPanelContent={HistoryPanelContent}
                                onCellKeyDown={(_params, event) => {
                                    event.defaultMuiPrevented = true;
                                }}
                                sx={(theme) => ({
                                    '& .MuiDataGrid-row': {
                                        alignItems: 'center',
                                        border: 'solid 1px #dddddd',
                                        borderRight: 'none',
                                        borderLeft: 'none',
                                        py: 1
                                    },
                                    '& [data-field="__detail_panel_toggle__"]': {
                                        userSelect: 'none',
                                        outline: '0 !important'
                                    },
                                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': { outline: 'none' },
                                    '& div.MuiDataGrid-withBorderColor': {
                                        border: 'none !important'
                                    },
                                    '& div.row-detail-panel-expanded': {
                                        backgroundColor: `${theme.palette.secondary.main} !important`,
                                        color: '#fff',
                                        '& svg[data-testid="ExpandMoreIcon"]': {
                                            color: '#fff',
                                            transform: `rotateZ(180deg)`
                                        },
                                        '& div.MuiChip-root': {
                                            borderColor: '#fff'
                                        },
                                        '& p': {
                                            color: '#fff !important'
                                        }
                                    }
                                })}
                                disableRowSelectionOnClick
                                disableColumnMenu
                                disableColumnFilter
                                disableColumnSelector
                                hideFooter
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5 }}>
                        <Button variant="contained" color="secondary" onClick={onClose} size="small">
                            Done
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default LineItemHistoryDialog;
