import { Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, ClickAwayListener, IconButton, SxProps, Typography } from '@mui/material';
import { ChangeEvent, MouseEvent, useRef, useState, useEffect, ReactNode } from 'react';
import { CustomMultiSelectOption } from './types';
import { List, CustomMultiSelectContainer } from './components';
import { IconDotsVertical } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';

export type CustomMultiSelectProps = {
    options: CustomMultiSelectOption[];
    value: number[];
    onChange: (selectedOptions: number[]) => void;
    leftButtonLabel?: ReactNode;
    width?: string;
    sx?: SxProps;
    label?: JSX.Element;
    variant?: 'outlined' | 'standalone';
    ExpandIcon?: JSX.Element;
    ContractIcon?: JSX.Element;
    disabled?: boolean;
};

export const CustomMultiSelect = ({
    options,
    value,
    onChange,
    leftButtonLabel,
    width,
    sx,
    label,
    variant = 'standalone',
    ExpandIcon,
    ContractIcon,
    disabled
}: CustomMultiSelectProps) => {
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [searchText, setSearchText] = useState('');
    // Made this way to ensure that the render match the configured order
    const [selectedOptions, setSelectedOptions] = useState(options.filter((el) => value.includes(el.id)).map((el) => el.id));
    const filteredOptions = options.filter((el) => el.label.toLowerCase().includes(searchText.toLowerCase()));
    const open = Boolean(anchorEl);
    const [allSelected, setAllSelected] = useState(false);
    const theme = useTheme();

    const handleOpenOptions = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setTimeout(() => {
            if (searchInputRef.current) searchInputRef.current.focus();
        }, 100);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const newVal = e.target.value;
        setSearchText(newVal);
    };

    const handleChangeSelectedOptions = (selectedIds: number[]) => () => {
        // Made this way to ensure that the render match the configured order
        setSelectedOptions(options.filter((el) => selectedIds.includes(el.id)).map((el) => el.id));
        onChange(options.filter((el) => selectedIds.includes(el.id)).map((el) => el.id));
    };

    const handleSelectAll = () => {
        const allOptionIds = options.map((option) => option.id);
        setSelectedOptions(allOptionIds);
        onChange(allOptionIds);
        setAllSelected(true);
    };

    const handleUnselectAll = () => {
        setSelectedOptions([]);
        onChange([]);
        setAllSelected(false);
    };

    useEffect(() => {
        setAllSelected(selectedOptions.length === options.length);
    }, [selectedOptions, options]);

    return (
        <ClickAwayListener onClickAway={handleClose} sx={{ backgroundColor: disabled ? '#E0E0E0' : '#FFFFFF' }}>
            <>
                {variant === 'outlined' ? (
                    <CustomMultiSelectContainer ref={containerRef} open={open} sx={{ backgroundColor: disabled ? '#E0E0E0' : '#FFFFFF' }}>
                        <Box
                            ref={containerRef}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                width: width || '200px',
                                height: '100%',
                                backgroundColor: disabled ? '#E0E0E0' : '#FFFFFF',
                                ...sx
                            }}
                        >
                            <Typography
                                fontSize="14px"
                                sx={{
                                    cursor: value ? 'pointer' : 'auto',
                                    maxHeight: '100%',
                                    maxWidth: '90%',
                                    flexGrow: 1,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontWeight: 500
                                }}
                            >
                                {options
                                    .filter((el) => selectedOptions.includes(el.id))
                                    .map((el) => el.label)
                                    .join(', ')}
                            </Typography>

                            {!disabled && selectedOptions.length > 0 && (
                                <IconButton disableRipple onClick={handleChangeSelectedOptions([])} sx={{ ml: 'auto', pr: 0 }}>
                                    <Close htmlColor="#54595E" />
                                </IconButton>
                            )}

                            {disabled && (
                                <IconButton
                                    sx={{ color: theme.palette.mode === 'dark' ? 'primary.main' : 'inherit', cursor: 'pointer' }}
                                    onClick={handleOpenOptions}
                                >
                                    <IconDotsVertical
                                        stroke={1.5}
                                        aria-controls={open ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        style={{ fontSize: '1.5rem' }}
                                    />
                                </IconButton>
                            )}

                            {!disabled && (
                                <IconButton disableRipple onClick={handleOpenOptions} sx={{ ml: 'auto', px: 0 }}>
                                    {open
                                        ? ContractIcon ?? <ExpandLess htmlColor="#54595E" />
                                        : ExpandIcon ?? <ExpandMore htmlColor="#54595E" />}
                                </IconButton>
                            )}

                            <List
                                open={open}
                                anchorEl={anchorEl}
                                searchInputRef={searchInputRef}
                                containerRef={containerRef}
                                searchText={searchText}
                                options={options}
                                filteredOptions={filteredOptions}
                                onClose={handleClose}
                                onChangeSearch={handleChangeSearch}
                                selectedOptions={selectedOptions}
                                onSelectOption={handleChangeSelectedOptions}
                                onClearSearch={() => setSearchText('')}
                                onSelectAll={handleSelectAll}
                                onUnselectAll={handleUnselectAll}
                                allSelected={allSelected}
                                disabled={disabled}
                            />
                        </Box>
                    </CustomMultiSelectContainer>
                ) : (
                    <Box
                        ref={containerRef}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: width || '200px',
                            height: '40px',
                            backgroundColor: disabled ? '#E0E0E0' : '#FFFFFF',
                            ...sx
                        }}
                    >
                        {selectedOptions.length > 0 && (
                            <Typography
                                fontSize="14px"
                                sx={{
                                    cursor: value ? 'pointer' : 'auto',
                                    maxHeight: '100%',
                                    maxWidth: '90%',
                                    flexGrow: 1,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontWeight: 500
                                }}
                            >
                                {options
                                    .filter((el) => selectedOptions.includes(el.id))
                                    .map((el) => el.label)
                                    .join(', ')}
                            </Typography>
                        )}

                        {!disabled && selectedOptions.length > 0 && (
                            <IconButton disableRipple onClick={handleChangeSelectedOptions([])} sx={{ ml: 'auto', pr: 0 }}>
                                <Close htmlColor="#54595E" />
                            </IconButton>
                        )}

                        {disabled && (
                            <IconButton
                                sx={{ color: theme.palette.mode === 'dark' ? 'primary.main' : 'inherit', cursor: 'pointer' }}
                                onClick={handleOpenOptions}
                            >
                                <IconDotsVertical
                                    stroke={1.5}
                                    aria-controls={open ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    style={{ fontSize: '1.5rem' }}
                                />
                            </IconButton>
                        )}

                        {!disabled && (
                            <IconButton disableRipple onClick={handleOpenOptions} sx={{ ml: 'auto' }}>
                                {open
                                    ? ContractIcon ?? <ExpandLess htmlColor="#54595E" />
                                    : ExpandIcon ?? <ExpandMore htmlColor="#54595E" />}
                            </IconButton>
                        )}

                        <List
                            open={open}
                            anchorEl={anchorEl}
                            searchInputRef={searchInputRef}
                            containerRef={containerRef}
                            searchText={searchText}
                            options={options}
                            filteredOptions={filteredOptions}
                            onClose={handleClose}
                            onChangeSearch={handleChangeSearch}
                            selectedOptions={selectedOptions}
                            onSelectOption={handleChangeSelectedOptions}
                            onClearSearch={() => setSearchText('')}
                            onSelectAll={handleSelectAll}
                            onUnselectAll={handleUnselectAll}
                            allSelected={allSelected}
                            disabled={disabled}
                        />
                    </Box>
                )}
            </>
        </ClickAwayListener>
    );
};
