import React, { useState, useEffect } from 'react';
import { RemoveCircle } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, Paper, Typography, styled } from '@mui/material';
import { FileViewer } from '../Attachments/components';
import { AttachmentRow } from '../Attachments/types';
import { S3ImgUrlGetter } from 'utils/S3Uploader';
import { v4 as uuidv4 } from 'uuid';
import useUser from 'hooks/useUser';

const StyledPaper = styled(Paper)(({ theme }) => ({
    border: `1px dashed #37326E`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1)
}));

interface FileCardProps {
    file: File | null;
    onRemove?: () => void;
    description?: string;
    fileKeyS3: string;
    isNewFile?: boolean;
    onChangeDescription?: (description: string) => void;
}

const FileCard = ({ file, onRemove, description, fileKeyS3, isNewFile, onChangeDescription }: FileCardProps) => {
    const { currentUser, userFromToken } = useUser();

    const [isOpenPreview, setIsOpenPreview] = useState(false);
    const [selectedRow, setSelectedRow] = useState<AttachmentRow | null>(null);
    const [fileSignedUrl, setFileSignedUrl] = useState('');
    const [ownFile, setOwnFile] = useState<File | null>(file);
    const [isFileLoading, setIsFileLoading] = useState(false);

    const handleOnRemove = () => {
        setSelectedRow(null);
        setOwnFile(null);
        setFileSignedUrl('');
        onRemove?.();
    };

    useEffect(() => {
        if (fileKeyS3 && !isNewFile) {
            (async () => {
                setIsFileLoading(true);
                const url = await S3ImgUrlGetter(fileKeyS3);
                const response = await fetch(url);
                const blob = await response.blob();
                const fileFromUrl = new File([blob], fileKeyS3, { type: blob.type });
                setFileSignedUrl(url);
                setOwnFile(fileFromUrl);
                setIsFileLoading(false);
            })();
        }
    }, [fileKeyS3, isNewFile]);

    if (!ownFile) return null;

    const fileName = ownFile.name;
    const fileSize = ownFile.size >= 1048576 ? `${(ownFile.size / 1048576).toFixed(2)} MB` : `${(ownFile.size / 1024).toFixed(2)} KB`;

    const onOpenFile = async () => {
        const fileToOPen = isNewFile ? file : ownFile;

        if (!fileToOPen) return;

        const fileType = `application/${fileToOPen.name.split('.')?.slice(-1)?.[0] || ''}`;

        setSelectedRow({
            id: uuidv4(),
            name: fileToOPen.name,
            description: description || '',
            fileType,
            fileSize: (fileToOPen.size / 1024).toFixed(2),
            createdAt: new Date().toISOString(),
            createdBy: userFromToken?.userId,
            createdByName: currentUser?.name ?? '',
            fileUrl: fileKeyS3
        });

        let url = '';
        if (!isNewFile) {
            url = fileToOPen.type === 'application/pdf' || fileType === 'application/pdf' ? await S3ImgUrlGetter(fileKeyS3) : '';
        } else {
            url = fileToOPen.type === 'application/pdf' || fileType === 'application/pdf' ? fileKeyS3 : '';
        }

        setFileSignedUrl(url);

        setIsOpenPreview(true);
    };

    const handleClosePreview = () => {
        setIsOpenPreview(false);
        setSelectedRow(null);
    };

    const onDownloadFile = async (row: AttachmentRow | null) => {
        if (!row) return;
        const urlToDownload = await S3ImgUrlGetter(row.fileUrl);
        const link = document.createElement('a');

        link.href = urlToDownload;
        link.download = row?.name;
        link.click();
    };

    if (isFileLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}>
                <CircularProgress size={24} />
            </Box>
        );
    }

    return (
        <>
            <StyledPaper elevation={0}>
                <InvisibleButton onClick={() => onOpenFile()}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '80%' }}>
                        <Typography variant="body1" component="span" fontWeight="medium" color={ownFile.type ? 'primary' : 'text.primary'}>
                            {fileName}
                        </Typography>
                        <Box sx={{ width: '100%', maxWidth: 300 }}>
                            <Typography variant="body2" component="div">
                                {description || 'No description'}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '10%', justifyContent: 'flex-end' }}>
                        <Typography variant="body2" color="text.secondary">
                            {fileSize}
                        </Typography>
                    </Box>
                </InvisibleButton>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '10%', justifyContent: 'flex-end' }}>
                    <IconButton onClick={handleOnRemove} color="error" size="small" aria-label="remove file">
                        <RemoveCircle />
                    </IconButton>
                </Box>
            </StyledPaper>
            {isOpenPreview && selectedRow && fileSignedUrl && (
                <FileViewer
                    open={isOpenPreview}
                    fileRow={selectedRow}
                    signedUrl={fileSignedUrl}
                    handleClose={handleClosePreview}
                    onDownload={onDownloadFile}
                    openRightPanel
                    onChangeDescription={onChangeDescription}
                />
            )}
        </>
    );
};

export default FileCard;

interface InvisibleButtonProps {
    onClick?: () => void;
    children: React.ReactNode;
}

const InvisibleButton: React.FC<InvisibleButtonProps> = ({ onClick, children }) => (
    <Button
        onClick={onClick}
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: 0,
            backgroundColor: 'transparent',
            border: 'none',
            boxShadow: 'none',
            textTransform: 'none',
            '&:hover': {
                backgroundColor: 'transparent',
                boxShadow: 'none'
            },
            '&:focus': {
                outline: 'none'
            },
            '&:active': {
                boxShadow: 'none'
            }
        }}
    >
        {children}
    </Button>
);
