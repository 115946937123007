import React from 'react';
import { StyledChip } from './components';
import { Box, Stack, SxProps } from '@mui/material';
import { useIntl } from 'react-intl';

export type InlineOptionPickerProps = {
    options: { label: string; value: any }[];
    value: string | undefined;
    onChange: (newValue: string) => void;
    error?: boolean;
    fullWidth?: boolean;
    width?: string;
    clearable?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    noTabIndex?: boolean;
    containerSx?: SxProps;
};

export const InlineOptionPicker = ({
    options,
    value,
    onChange,
    error,
    fullWidth,
    width,
    clearable = true,
    readOnly,
    disabled,
    noTabIndex,
    containerSx
}: InlineOptionPickerProps) => {
    const intl = useIntl();
    const handleClick = (newVal: any) => () => {
        if (clearable) onChange(newVal !== value ? newVal : '');
        else onChange(newVal);
    };

    return (
        <Box
            sx={{
                ...containerSx
            }}
        >
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    background: disabled ? '#e0e0e0' : '#f5f6f7',
                    borderRadius: '8px',
                    border: error ? '1px solid #EF5350' : 'none',
                    width: width || (fullWidth ? '100%' : 'fit-content'),
                    px: '12px',
                    py: '4px',
                    position: 'relative',
                    flexFlow: 'row wrap',
                    pb: 1
                }}
            >
                {options.map((option) => (
                    <StyledChip
                        sx={{
                            m: 0.5
                        }}
                        key={option.value}
                        tabIndex={noTabIndex ? -1 : undefined}
                        label={
                            // eslint-disable-next-line no-nested-ternary
                            option?.label
                                ? intl.formatMessage({ id: option?.label, defaultMessage: option?.label })
                                : option?.label
                                ? option?.label
                                : ''
                        }
                        variant="outlined"
                        selected={value === option.value}
                        onClick={handleClick(option.value)}
                    />
                ))}
                {(readOnly || disabled) && <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />}
            </Stack>
        </Box>
    );
};
