import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ApolloClient, InMemoryCache, ApolloProvider, concat, DefaultOptions, ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
// load mock apis
import '_mockApis';

// project imports
import App from 'App';
import { AWS_CONFIGURATION, BASE_PATH } from 'config';
import { dispatch, store } from 'store';
import * as serviceWorker from 'serviceWorker';
import reportWebVitals from 'reportWebVitals';
import firebase from 'firebase/compat/app';
import { ConfigProvider } from 'contexts/ConfigContext';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { typePolicies } from 'utils/apolloHelpers';

// style + assets
import 'assets/scss/style.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LicenseInfo } from '@mui/x-license-pro';
import ReactGA from 'react-ga4';
import { GoogleAnalyticsEvent } from 'types/google-analytics';
// import { LicenseInfo } from '@mui/x-license-pro';
import { Amplify } from 'aws-amplify';
import { show } from 'store/slices/modal';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE ?? '');

// const cache = new InMemoryCache();

const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_URL
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const tenant_id = localStorage.getItem('tenant_id') || undefined;
    const authorization = localStorage.getItem('serviceToken');
    const backend_jwt = localStorage.getItem('backend_jwt');
    return {
        headers: {
            ...headers,
            tenant_id,
            authorization,
            backend_jwt
        }
    };
});

const logoutLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors?.some((error) => error.message.includes('TokenExpiredError'))) {
        firebase.auth().signOut();
        dispatch(show({}));
    }

    if (graphQLErrors?.some((error) => error.extensions.code === 'UNAUTHENTICATED')) {
        dispatch(show({}));
    }
});

const defaultOptions: DefaultOptions = {
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    }
};

export const client = new ApolloClient({
    link: concat(authLink.concat(logoutLink), uploadLink as unknown as ApolloLink),
    cache: new InMemoryCache({
        addTypename: false,
        typePolicies
    }),
    defaultOptions
});

if (process.env.NODE_ENV === 'production') ReactGA.initialize(process.env.REACT_APP_GOOGLE_MEASUREMENT_ID ?? '');

// ========================|| Google Analytics Event Handle  ||======================== //

/**
 * Handles Google Analytics event tracking.
 *
 * @param {string} action - The action being tracked
 * @param {string} category - The category of the action
 * @param {string} [label] - Optional label for the event
 * @param {number} [value] - Optional value for the event
 * @return {void}
 */
export const GoogleAnalyticsEventHandler = (event: GoogleAnalyticsEvent): void => {
    ReactGA.event(event);
};

Amplify.configure(AWS_CONFIGURATION);

// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider>
            <ApolloProvider client={client}>
                <BrowserRouter basename={BASE_PATH}>
                    <App />
                </BrowserRouter>
            </ApolloProvider>
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
