import { HighlightOff, History } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, ToggleButtonGroup, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { LineItemIcon } from 'views/DocumentViewer/components/Icons';
import { IconMessageCircle2, IconPaperclip } from '@tabler/icons';
import { LogReportMode, PanelTitle } from '../utils';
import useIsAllowedByRecordType from 'hooks/useIsAllowedByRecordType';
import { useTheme } from '@mui/styles';
import { FooterOptions } from '../Footer';
import { FilePdf } from 'ui-component/icons';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface HeaderPanelProps {
    toggleEditForm?: () => void;
    toggleLogReport: () => void;
    changePanel: PanelTitle;
    logReportMode: LogReportMode;
    isCreate?: boolean;
    onToggleLineItems?: () => void;
    isObjectPanel?: boolean;
    onGoBack?: () => void;
    onToggleComments?: () => void;
    onToggleAttachments?: () => void;
    recordTypeId?: number;
    isAttachmentFieldAllowed: boolean;
    handleClickSave: () => Promise<void>;
    handleClickCancel?: () => void;
    loading: boolean;
    isRecordView?: boolean;
    recordDataId?: number;
    hideSaveAnOrder?: boolean;
    disabledByRequired?: boolean;
    onToggleRecordView?: () => void;
    hideButtons?: boolean;
    hideLogReport?: boolean;
}

const SideBar = ({
    toggleEditForm,
    changePanel,
    logReportMode,
    toggleLogReport,
    isCreate,
    onToggleLineItems,
    onGoBack,
    isObjectPanel = false,
    onToggleComments,
    onToggleAttachments,
    recordTypeId,
    isAttachmentFieldAllowed,
    handleClickSave,
    handleClickCancel,
    loading,
    isRecordView,
    recordDataId,
    hideSaveAnOrder,
    disabledByRequired = false,
    onToggleRecordView,
    hideButtons,
    hideLogReport
}: HeaderPanelProps) => {
    const theme = useTheme();
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);
    const recordId = queryParams.get('recordId') || '';
    const [pdfViewerAnchorEl, setPdfViewerAnchorEl] = useState<null | HTMLElement>(null);

    const { isAllowed: isAllowedToAccessLogReport } = useIsAllowedByRecordType('log report access', recordTypeId);

    const { isAllowed: isAllowedToAccessLineItems } = useIsAllowedByRecordType('line item access', recordTypeId);
    const { isAllowed: isAllowedToPdfViewer } = useIsAllowedByRecordType('pdf record view', recordTypeId);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
                backgroundColor: '#F5F6F7',
                height: '100%',
                padding: 0.5
            }}
            data-testId="header-panel"
        >
            <Box gap={2} width="95%" display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                {isCreate && (
                    <Box>
                        {isObjectPanel && (
                            <Box>
                                <ToggleButtonGroup
                                    sx={{ display: { xs: 'flex', md: 'flex' }, justifyContent: 'flex-end', '& button': { height: '100%' } }}
                                >
                                    <Tooltip title="Close Object Panel">
                                        <IconButton
                                            onClick={() => onGoBack?.()}
                                            value="Close Object Panel"
                                            size="medium"
                                            sx={{
                                                color: theme.palette.primary[400],
                                                backgroundColor: '#FFF',
                                                fontWeight: 600,
                                                '&:hover': {
                                                    color: theme.palette.primary[400],
                                                    backgroundColor: theme.palette.primary[300]
                                                }
                                            }}
                                        >
                                            <HighlightOff />
                                        </IconButton>
                                    </Tooltip>
                                </ToggleButtonGroup>
                            </Box>
                        )}
                    </Box>
                )}

                {!isCreate && isAllowedToAccessLogReport && !hideLogReport && (
                    <>
                        {changePanel !== PanelTitle.LogReport && (
                            <Box>
                                <Tooltip title="Log report">
                                    <IconButton
                                        onClick={toggleLogReport}
                                        value="Log report"
                                        size="medium"
                                        sx={{
                                            color: theme.palette.primary[400],
                                            backgroundColor: '#FFF',
                                            fontWeight: 600,
                                            '&:hover': {
                                                color: theme.palette.primary[400],
                                                backgroundColor: theme.palette.primary[300]
                                            }
                                        }}
                                    >
                                        <History fontSize="medium" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}

                        {isObjectPanel && (
                            <Tooltip title="Close Object Panel">
                                <IconButton
                                    onClick={() => onGoBack?.()}
                                    value="Close Object Panel"
                                    size="medium"
                                    sx={{
                                        color: theme.palette.primary[400],
                                        backgroundColor: '#FFF',
                                        fontWeight: 600,
                                        '&:hover': {
                                            color: theme.palette.primary[400],
                                            backgroundColor: theme.palette.primary[300]
                                        }
                                    }}
                                >
                                    <HighlightOff />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                )}

                {onToggleComments && !isCreate && changePanel !== PanelTitle.LogReport && (
                    <Box>
                        <Tooltip title="Comments">
                            <IconButton
                                onClick={onToggleComments}
                                size="medium"
                                sx={{
                                    color: theme.palette.primary[400],
                                    bgcolor: logReportMode === LogReportMode.Comments ? theme.palette.secondary.light : '#FFF',
                                    fontWeight: 600,
                                    height: '100%',
                                    '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                }}
                            >
                                <IconMessageCircle2
                                    color={
                                        logReportMode === LogReportMode.Comments ? theme.palette.secondary.main : theme.palette.primary[400]
                                    }
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}

                {onToggleAttachments && !isCreate && changePanel !== PanelTitle.LogReport && isAttachmentFieldAllowed && (
                    <Box>
                        <Tooltip title="Attachments">
                            <IconButton
                                onClick={onToggleAttachments}
                                size="medium"
                                sx={{
                                    color: theme.palette.primary[400],
                                    bgcolor: logReportMode === LogReportMode.Attachments ? theme.palette.secondary.light : '#FFF',
                                    fontWeight: 600,
                                    height: '100%',
                                    '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                }}
                            >
                                <IconPaperclip
                                    color={
                                        logReportMode === LogReportMode.Attachments
                                            ? theme.palette.secondary.main
                                            : theme.palette.primary[400]
                                    }
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}

                {!!onToggleLineItems && changePanel !== PanelTitle.LogReport && !isCreate && isAllowedToAccessLineItems && (
                    <Box>
                        <Tooltip title="Line Items">
                            <IconButton
                                onClick={onToggleLineItems}
                                value="line items"
                                size="medium"
                                sx={{
                                    color: theme.palette.primary[400],
                                    bgcolor: logReportMode === LogReportMode.LineItems ? theme.palette.secondary.light : '#FFF',
                                    fontWeight: 600,
                                    height: '100%',
                                    '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                }}
                            >
                                <LineItemIcon
                                    color={
                                        logReportMode === LogReportMode.LineItems
                                            ? theme.palette.secondary.main
                                            : theme.palette.primary[400]
                                    }
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}

                {onToggleRecordView && isAllowedToPdfViewer && (
                    <Box>
                        <Tooltip title="PDF View">
                            <IconButton
                                onClick={onToggleRecordView}
                                onContextMenu={(e) => {
                                    e.preventDefault();
                                    setPdfViewerAnchorEl(e.currentTarget);
                                }}
                                size="medium"
                                sx={{
                                    color: theme.palette.primary[400],
                                    backgroundColor: '#FFF',
                                    fontWeight: 600,
                                    '&:hover': {
                                        color: theme.palette.primary[400],
                                        backgroundColor: theme.palette.primary[300]
                                    }
                                }}
                            >
                                <FilePdf sx={{ color: theme.palette.primary[400] }} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={pdfViewerAnchorEl}
                            open={!!pdfViewerAnchorEl}
                            onClose={() => setPdfViewerAnchorEl(null)}
                            PaperProps={{ sx: { borderRadius: '8px', bgcolor: '#f5f6f7' } }}
                            anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                            <MenuItem
                                onClick={() => {
                                    setPdfViewerAnchorEl(null);
                                    onToggleRecordView();
                                }}
                            >
                                Open in a Modal
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setPdfViewerAnchorEl(null);
                                    const tenantUrl = localStorage.getItem('tenantPath');
                                    window.open(
                                        `${tenantUrl}/accounting/doc-viewer/?recordId=${recordId}&recordType=${recordTypeId}`,
                                        '_blank'
                                    );
                                }}
                            >
                                Open in a new tab
                            </MenuItem>
                        </Menu>
                    </Box>
                )}
            </Box>

            {!hideButtons &&
                !(
                    changePanel === PanelTitle.ViewObject ||
                    changePanel === PanelTitle.EditObject ||
                    changePanel === PanelTitle.LogReport
                ) && (
                    <>
                        <FooterOptions
                            panelState={changePanel}
                            onEdit={toggleEditForm}
                            onSave={handleClickSave}
                            onCancel={handleClickCancel}
                            isCreate={isCreate}
                            disabledButtons={loading}
                            isRecordView={isRecordView}
                            hideSaveAnOrder={hideSaveAnOrder}
                        />
                    </>
                )}
        </Box>
    );
};

export default SideBar;
