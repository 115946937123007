import { gql } from '@apollo/client';

export const MUTATION_UPDATE_USER_PREFERENCES = gql`
    mutation UpdateUserGridPreferences($data: UserGridPreferencesUpdateInput!) {
        updateUserGridPreferences(data: $data) {
            id
            tenantId
            gridName
            enabled
            savedName
            isDefault
            gridOptions {
                orderedFields
                hiddenFields
                filterModel {
                    columnField
                    operatorValue
                    value
                }
                pinnedColumns {
                    left
                    right
                }
                densityFactor
                pageSize
            }
        }
    }
`;

export const MUTATION_CREATE_USER_PREFERENCES = gql`
    mutation RegisterUserGridPreferences($data: UserGridPreferencesRegisterInput!) {
        registerUserGridPreferences(data: $data) {
            id
            tenantId
            gridName
            enabled
            savedName
            isDefault
            gridOptions {
                orderedFields
                hiddenFields
                filterModel {
                    columnField
                    operatorValue
                    value
                }
                pinnedColumns {
                    left
                    right
                }
                densityFactor
                pageSize
            }
        }
    }
`;

export const MUTATION_REGISTER_USER_GRID_PREFERENCES_DEFAULT = gql`
    mutation RegisterUserGridPreferencesDefault($data: CreateUserGridPreferencesDefaultInput!) {
        registerUserGridPreferencesDefault(data: $data) {
            id
            recordType {
                id
            }
            userGridPreference {
                id
            }
            roleGridPreference {
                id
            }
            enabled
        }
    }
`;

export const MUTATION_UPDATE_USER_GRID_PREFERENCES_DEFAULT = gql`
    mutation UpdateUserGridPreferencesDefault($data: UpdateUserGridPreferencesDefaultInput!) {
        updateUserGridPreferencesDefault(data: $data) {
            id
            recordType {
                id
            }
            userGridPreference {
                id
            }
            roleGridPreference {
                id
            }
            enabled
        }
    }
`;

export const MUTATION_UPDATE_MANY_USER_PREFERENCES = gql`
    mutation UpdateManyUserGridPreferences($data: UserBatchGridPreferencesRegisterInput!) {
        updateManyUserGridPreferences(data: $data) {
            id
            userId {
                validated
                id
            }
            enabled
            savedName
            isDefault
            createdAt
            updatedAt
            gridOptions {
                orderedFields
                hiddenFields
                filterModel {
                    columnField
                    operatorValue
                    value
                }
                pinnedColumns {
                    left
                    right
                }
                densityFactor
                pageSize
            }
            gridName
            tenantId
        }
    }
`;

export const MUTATION_UPDATE_RECORD_HEADER = gql`
    mutation UpdateRecordHeader($data: RecordHeaderUpdateInput!) {
        updateRecordHeader(data: $data) {
            id
            tenant {
                id
                name
            }
            targetTenant
            recordType {
                id
                name
            }
            recordNumber
            statusId {
                id
                name
            }
            problemCodeId {
                id
                name
            }
            enabled
            createdBy {
                id
                name
                email
            }
            createdAt
            receivedBy {
                id
                name
                email
            }
            receivedAt
            approvedBy {
                email
                name
                id
            }
            approvedAt
            assignedTo {
                id
                name
                email
            }
            updatedAt
            recordFile {
                id
            }
            targetTransmissionTenant {
                id
                name
            }
            isProject
            level
            parent {
                id
                recordType {
                    id
                    name
                }
            }
            children {
                id
                recordType {
                    id
                    name
                }
            }
            additionalFields {
                id
                objectValue {
                    id
                    objectValues {
                        value
                        tag
                        objectProperty {
                            id
                            name
                            isDisplayable
                            dataType
                            order
                        }
                    }
                }
                tag
                value
            }
        }
    }
`;

export const MUTATION_BULK_UPDATE_RECORD_HEADER = gql`
    mutation UpdateBulkRecordHeader($ids: [Float!]!, $data: RecordHeaderUpdateBulkInput!) {
        updateBulkRecordHeader(ids: $ids, data: $data) {
            id
            enabled
            createdBy {
                id
                name
                email
            }
            problemCodeId {
                id
                name
            }
            receivedAt
            receivedBy {
                id
                name
                email
            }
            recordNumber
            recordFile {
                id
            }
            recordType {
                id
                name
            }
            statusId {
                id
                name
            }
            targetTenant
            tenant {
                id
                name
            }
            updatedAt
            createdAt
            assignedTo {
                id
                name
                email
            }
            approvedBy {
                email
                name
                id
            }
            approvedAt
            targetTransmissionTenant {
                id
                name
            }
            additionalFields {
                id
                tag
                value
            }
        }
    }
`;
export const MUTATION_CREATE_FIELD_RECORD_HEADER = gql`
    mutation RegisterRecordHeader($data: RecordHeaderRegisterInput!) {
        registerRecordHeader(data: $data) {
            id
            additionalFields {
                id
                tag
                value
            }
        }
    }
`;
